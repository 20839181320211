<template>
  <div id="landing0323">
    <section
      class="img-container"
      style="background-image: url('/imgs/landing_0323/hero.png')"
    >
      <div class="container">
        <nav>
          <router-link class="nav-container" to="/">
            <img
              src="/svgs/logo_4.svg"
              alt="Logo NLC"
              class="logo_2 logo-nlc"
            />
          </router-link>

          <a href="mailto:info@nlcollege.es">
            <h5>{{ $lang("contactus") }}</h5>
          </a>
        </nav>
      </div>

      <div class="container py-7">
        <div class="row align-items-center">
          <div class="col-md-8">
            <h1 class="title" v-html="$lang('title')" />
            <h5 class="mb-5 subtitle" v-html="$lang('subtitle')" />

            <ul class="disc-style mb-6 mb-md-0">
              <li v-for="(a, i) of $lang('head_items')" :key="i">{{ a }}</li>
            </ul>

            <div class="button-container d-md-none">
              <button
                class="btn btn-primary d-md-none form-button"
                @click="goToForm('#formMobile')"
              >
                {{ $lang("video.button") }}
              </button>
            </div>
          </div>

          <form id="form" @submit.prevent="sendForm" :class="'col-md-4 form_' + $route.params.lang">
            <div class="text-center">
              <h4 class="form-title">{{ $lang("form.title") }}</h4>
              <h6 class="mb-5 form-subtitle">{{ $lang("form.subtitle") }}</h6>
            </div>

            <input
              type="text"
              :placeholder="$lang('form.name')"
              v-model="form.name"
              required
            />
            <input
              type="text"
              :placeholder="$lang('form.email')"
              v-model="form.email"
              required
            />
            <input
              type="text"
              :placeholder="$lang('form.phone')"
              v-model="form.phone"
              required
            />
            <Select
              v-model="form.nationality"
              :label="$lang('form.nationality')"
              :options="nationalities"
            />

            <div class="button-container">
              <button class="btn btn-primary form-button" type="submit">
                {{ $lang("form.button") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>

    <section class="py-7 text-center">
      <div class="container">
        <div class="mb-5">
          <i class="isologo mb-2"></i>
          <h2>{{ $lang("whylearn.title") }}</h2>
        </div>

        <div class="row">
          <div
            class="col-md-4 mb-6 mb-md-0"
            v-for="(t, i) of $lang('whylearn.testimonials')"
            :key="i"
          >
            <img :src="t.img" class="mb-4" alt="" />

            <h4>{{ t.name }}</h4>
            <a
              :href="t.linkedin"
              target="_blank"
              class="btn btn-dark btn-rounded btn-sm mb-4"
              v-if="t.linkedin"
            >
              <i class="fab fa-linkedin-in"></i>
            </a>
            <a
              :href="t.instagram"
              target="_blank"
              class="btn btn-dark btn-rounded btn-sm mb-4"
              v-if="t.instagram"
            >
              <i class="fab fa-instagram"></i>
            </a>

            <p>{{ t.comments }}</p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="img-container py-7"
      style="background-image: url('/imgs/landing_0323/hero-2.png')"
    >
      <div class="container text-center">
        <div class="mb-5">
          <i class="isologo mb-2"></i>
          <h2>{{ $lang("comments.title") }}</h2>
        </div>

        <div class="row">
          <div
            class="col-md-4 mb-6 mb-md-0"
            v-for="(c, i) of $lang('comments.items')"
            :key="i"
          >
            <h5>{{ c.name }}</h5>
            <div class="stars mb-4">
              <i class="fa fa-star" />
              <i class="fa fa-star" />
              <i class="fa fa-star" />
              <i class="fa fa-star" />
              <i class="fa fa-star" />
            </div>

            <p class="feedback-body">{{ c.body }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="py-md-6 pt-6">
      <div class="container text-center">
        <i class="isologo"></i>
        <h2>{{ $lang("video.title") }}</h2>

        <div class="py-md-6 pt-6">
          <iframe
            style="width: 100%; height: 100%; min-height: 500px"
            :src="$lang('video.url')"
            title="Bienvenido a NL College Barcelona Campus"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>

        <button
          class="btn btn-primary d-none d-md-block button-video"
          @click="goToForm('#form')"
        >
          {{ $lang("video.button") }}
        </button>
      </div>
    </section>
    <footer>
      <div class="container">
        <form id="formMobile" @submit.prevent="sendForm">
          <div class="text-center">
            <h4 class="form-title">{{ $lang("form.title") }}</h4>
            <h6 class="mb-5 form-subtitle">{{ $lang("form.subtitle") }}</h6>
          </div>

          <input
            type="text"
            :placeholder="$lang('form.name')"
            v-model="form.first_name"
            required
          />
          <input
            type="text"
            :placeholder="$lang('form.email')"
            v-model="form.email"
            required
          />
          <input
            type="text"
            :placeholder="$lang('form.phone')"
            v-model="form.phone"
            required
          />
          <Select
            v-model="form.nationality"
            :label="$lang('form.nationality')"
            :options="nationalities"
          />

          <div class="button-container-mobile">
            <button class="btn btn-primary form-button-mobile" type="submit">
              {{ $lang("form.button") }}
            </button>
          </div>
        </form>

        <div class="row align-items-center">
          <div
            class="col-md-4 d-flex justify-content-center justify-content-md-start mb-4 mb-md-0"
          >
            <img src="/svgs/logo_2.svg" alt="Logo NLC" class="logo-nlc" />
          </div>
          <div class="col-md-4 d-flex flex-column gap-2 align-items-center">
            <a href="http://nlcollege.es" target="_blank"> nlcollege.es </a>
            <a href="mailto:info@nlcollege.es" target="_blank">
              info@nlcollege.es
            </a>

            <div class="socials">
              <a
                href="https://www.instagram.com/nlcollege.spain/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a
                href="https://ne-np.facebook.com/nlcollege/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.tiktok.com/@nlcollege.spain"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-tiktok"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/nlcollege-spain/mycompany/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div
            class="col-md-4 d-flex justify-content-md-end justify-content-center mt-4 mt-md-0"
          >
            <p>© 2023</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import countries_es from "@/assets/json/countries-es.json";
import countries_en from "@/assets/json/countries-en.json";
import Select from "@/components/Forms/Select.vue";
import axios from "axios";

export default {
  components: {
    Select,
  },
  data: () => ({
    form: {
      name: null,
      email: null,
      phone: null,
      nationality: null,
    },
    countries_en,
    countries_es,
  }),
  computed: {
    nationalities() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.nationality,
        label: x.nationality,
      }));
    },
  },
  methods: {
    goToForm(id) {
      document.querySelector(id).scrollIntoView();
    },
    sendForm() {
      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-phone": this.form.phone,
            "your-email": this.form.email,
            "your-name": this.form.name,
            "your-nationality": this.form.nationality,
            token:
              this.$route.params.lang === "en"
                ? "pbme042079csei4x1195slx46942wk2kz"
                : "0409ga20798numu81195zak46942u2bee",
          },
        })
        .then(() => {
          this.$router.push({ name: "thanks" });
        });
    },
  },
};
</script>

<style></style>
